.credits-container {
    margin-top: 10px; /* Espacio superior */
    text-align: center;
    font-size: 0.9rem; /* Tamaño de fuente reducido */
    color: #555; /* Color del texto */
  }
  
  .credits-text a {
    color: #007bff; /* Color del enlace */
    text-decoration: none;
  }
  
  .credits-text a:hover {
    text-decoration: underline;
  }
  