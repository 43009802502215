/* Fondo principal */
body {
    background-color: #483333; /* Fondo oscuro */
    color: #FFFFFF; /* Texto en blanco */
    font-family: Arial, sans-serif;
}

/* Botón principal */
.btn-primary {
    background-color: #f64012; /* Naranja intenso */
    color: #FFFFFF; /* Texto en blanco */
    border: none;
}

.btn-primary:hover {
    background-color: #d9380e; /* Versión ligeramente más oscura para hover */
}

/* Tarjetas y contenedores */
.card {
    background-color: #FFFFFF; /* Fondo blanco para contraste */
    color: #483333; /* Texto en marrón oscuro */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera para resaltar */
    padding: 20px;
}

/* Títulos */
h1, h2, h3 {
    color: #f64012; /* Naranja intenso para títulos */
}

/* Texto y enlaces */
a {
    color: #f64012;
    text-decoration: none;
}

a:hover {
    color: #d9380e; /* Naranja más oscuro en hover */
}

/* Bordes y líneas divisorias */
.divider {
    border-top: 1px solid #f64012; /* Naranja intenso */
}

/* Estilo del logo */
.logo {
    width: 100px;
    height: auto;
    border-radius: 10%; /* Forma redonda */
    background-color: #FFFFFF; /* Fondo blanco alrededor del logo */
    padding: 5px; /* Espacio alrededor del logo */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para destacar */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animación */
}

.logo:hover {
    transform: scale(1.1); /* Aumenta ligeramente en hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Sombra más profunda en hover */
}
