/* Estilo del contenedor para desplazamiento horizontal */
.days-container {
    display: flex;
    overflow-x: auto; /* Solo permite scroll horizontal */
    overflow-y: hidden; /* Deshabilita el scroll vertical */
    padding: 10px;
    gap: 10px;
    scrollbar-width: thin; /* Personaliza el scroll en navegadores que lo soporten */
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
    height: 80px; /* Aumentamos el alto para evitar que se pierda la imagen */
    min-height: 80px; /* Asegura que el alto no sea menor al valor ajustado */
    justify-content: center; /* Centra los días horizontalmente */
}

/* Personalización del scroll */
.days-container::-webkit-scrollbar {
    height: 6px; /* Altura del scroll horizontal */
}

.days-container::-webkit-scrollbar-thumb {
    background-color: #f64012; /* Color del scroll */
    border-radius: 10px;
}

/* Estilos del botón circular */
.day-button {
    width: 60px;  /* Asegúrate de que los botones tengan un tamaño fijo */
    height: 60px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #f64012;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    padding: 0;
    transform-origin: center center; /* Garantiza que el crecimiento sea desde el centro */
}

/* Efecto de hover */
.day-button:hover {
    transform: scale(0.9); /* Efecto de zoom en hover */
    cursor: pointer;
}

/* Estilo de la imagen dentro del botón */
.day-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Estilos para el contenedor de los días (en pantallas pequeñas) */
@media (max-width: 768px) {
    .days-container {
        padding: 0 15px; /* Espaciado adicional para pantallas pequeñas */
        height: auto; /* Ajuste del alto para pantallas pequeñas */
        min-height: 70px; /* Asegura que el alto no sea menor al valor ajustado */
    }
}

