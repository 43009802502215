/* Contenedor principal de la evaluación */
.evaluation-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #3a4149;
}

/* Título principal */
.evaluation-card h4 {
    color: #f64012;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
}

/* Contenedor de género con centrado */
.gender-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

/* Ícono de género */
.gender-icon {
    font-size: 1.5rem;
    color: #007bff;
    margin-right: 8px;
}

/* Texto de género */
.gender-text {
    font-size: 1.2rem;
    font-weight: 500;
    color: #483333;
}

/* Sección de historial médico */
.medical-history,
.evaluation-details,
.final-observations {
    padding: 10px 0;
    border-top: 1px solid #ddd;
    margin-top: 15px;
}

/* Encabezados de sección */
.medical-history h5,
.evaluation-details h5,
.final-observations h5 {
    font-weight: bold;
    color: #f64012;
    margin-bottom: 10px;
}

/* Lista del historial médico */
.medical-history ul {
    list-style-type: none;
    padding: 0;
    color: #3a4149;
    font-size: 1rem;
}

.medical-history li {
    margin-bottom: 6px;
    font-weight: 500;
}

/* Detalles de la evaluación */
.evaluation-details p,
.final-observations p {
    margin: 5px 0;
    color: #3a4149;
    font-size: 1rem;
}

/* Texto de observaciones */
.final-observations p {
    font-style: italic;
    color: #483333;
}

/* Botón personalizado */
.custom-button {
    background-color: #f64012;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.2s ease;
    text-align: center;
    display: inline-block;
    margin-top: 15px;
    width: 100%;
}

.custom-button:hover {
    background-color: #c23010;
    box-shadow: 0 4px 8px rgba(246, 64, 18, 0.2);
}

/* Estilo responsivo para pantallas pequeñas */
@media (max-width: 768px) {
    .evaluation-card {
        padding: 15px;
        margin: 20px auto;
    }

    .gender-icon {
        font-size: 1.3rem;
    }

    .medical-history ul,
    .evaluation-details p,
    .final-observations p {
        font-size: 0.9rem;
    }
}
