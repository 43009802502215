/* Reset básico y configuración de tipografía */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background-color: #FFFFFF; /* Fondo oscuro */
    color: #FFFFFF; /* Texto en blanco */
}

/* Contenedor principal para ShowcaseProfile */
.showcase-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Fondo claro para un aspecto profesional */
    padding: 20px;
    box-sizing: border-box;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
    .showcase-profile-container {
        padding: 10px;
    }
}
