/* Estilos de la tarjeta de ejercicio */
.workout-card {
    width: 100%;
    max-width: 280px; /* Reduce un poco el ancho máximo para una mejor visualización */
    margin: 15px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Aumenta la sombra para mayor profundidad */
    overflow: hidden; /* Asegura que los bordes redondeados afecten a la imagen */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .workout-card:hover {
    transform: scale(1.02); /* Aumenta ligeramente el tamaño al pasar el cursor */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .workout-card-image {
    width: 100%;
    height: auto; /* Permite que la imagen mantenga su proporción */
    max-height: 180px; /* Fija la altura máxima sin recortar la imagen */
    object-fit: contain; /* Asegura que la imagen se muestre completa */
    background-color: #f4f4f4; /* Fondo claro detrás de la imagen */
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #f64012;
    margin-bottom: 5px; /* Espacio debajo del título */
  }
  
  .workout-details {
    margin-top: 10px;
  }
  
  .workout-details p {
    font-size: 0.9rem;
    margin: 5px 0;
    color: #555; /* Color de texto más suave */
  }
  
  .workout-detail-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin-top: 15px;
    background-color: #f64012;
    color: #ffffff;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .workout-detail-button:hover {
    background-color: #c52e0b;
    transform: translateY(-2px); /* Efecto de elevación en hover */
  }
  