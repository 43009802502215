/* Contenedor de las tarjetas de nutrición */
.nutrition-plan-container {
    display: grid;
    gap: 20px;
    width: 100%;
    padding: 20px;
}

.nutrition-plan-container h4 {
    text-align: center;
    margin-bottom: 20px; /* Espacio debajo del título */
}

/* Configuración de columnas responsiva */
@media (min-width: 576px) {
    /* Dos columnas en pantallas pequeñas */
    .nutrition-plan-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) {
    /* Tres columnas en pantallas medianas (tablets) */
    .nutrition-plan-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 992px) {
    /* Cuatro columnas en pantallas grandes (desktops) */
    .nutrition-plan-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1200px) {
    /* Cinco columnas en pantallas extra grandes */
    .nutrition-plan-container {
        grid-template-columns: repeat(5, 1fr);
    }
}
