/* Contenedor principal del detalle de ejercicio */
.exercise-detail-container {
    background-color: #f4f4f4;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: Arial, sans-serif;
}

/* Sección multimedia */
.media-section {
    text-align: center;
}

.exercise-video {
    width: 100%;
    height: 350px;
    margin: 10px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.exercise-subtitle {
    font-size: 1.2em;
    color: #666;
    font-weight: 500;
}

/* Estilo de tabla para detalles del ejercicio */
.details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
}

.detail-item {
    padding: 12px 20px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.detail-item:nth-child(odd) {
    background-color: #f9f9f9;
}

.detail-item:last-child {
    border-bottom: none;
}

/* Efecto de hover para ambas columnas de cada fila */
.detail-item:hover {
    background-color: #f64012;
    color: #ffffff;
}

.detail-title {
    font-weight: bold;
    color: #333;
    text-transform: capitalize;
}

.detail-value {
    color: #f64012;
    font-weight: bold;
}

/* Sección de cálculos */
.calculations-section {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #333;
}

.calculation-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    font-weight: 500;
    border-top: 1px solid #e0e0e0;
}

.calculation-item:first-child {
    border-top: none;
}

/* Sección de entrada de peso y botón de guardado */
.input-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.weight-input {
    width: 100px;
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.weight-input:focus {
    border-color: #f64012;
    outline: none;
}

.save-button {
    padding: 8px 16px;
    font-size: 1em;
    color: #ffffff;
    background-color: #f64012;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.save-button:hover {
    background-color: #c23010;
    transform: translateY(-2px);
}

/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
    .details-grid {
        grid-template-columns: 1fr;
    }

    .exercise-video {
        height: 250px;
    }
}
