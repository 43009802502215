/* SnackProfileNutritionImage.css */
.nutrition-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .nutrition-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Bordes redondeados */
  }
  