/* Estilos personalizados para la tarjeta de información del usuario */
.profile-info-card {
    background-color: #ffffff;
    border-radius: 12px;
    color: #483333;
    max-width: 400px;
    margin: 40px auto; /* Separación de la parte superior */
    padding: 20px; /* Espacio interno adicional */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Logo de usuario */
.user-logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #f64012;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-right: 15px; /* Separación entre el logo y el nombre */
}

.user-logo:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Nombre del usuario */
.user-name {
    color: #f64012;
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 15px; /* Separación izquierda para centrar el nombre */
}

.user-details {
    margin-top: 20px; /* Separación adicional entre el nombre y los detalles */
}

.user-details p {
    font-size: 1rem;
    color: #483333;
    margin-bottom: 0.8rem;
}

.user-details strong {
    color: #3a4149;
}
