/* Contenedor principal */
.profile-container {
    background-color: #f4f4f4;
    color: #483333;
}

/* Navbar personalizado con fondo degradado */
.navbar {
    background: linear-gradient(135deg, #1b1f23, #3a4149); /* Degradado oscuro */
    border-bottom: 3px solid #f64012; /* Línea inferior naranja */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    padding: 14px 24px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Logo y nombre de la marca */
.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
}

.brand-name {
    color: #e6e6e6;
    font-weight: 600;
    margin-left: 10px;
}

/* Logo */
.logo {
    width: 100px;
    height: auto;
    border-radius: 10%; /* Forma circular */
    background-color: #ffffff;
    padding: 2px;
    margin-right: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border: 2px solid #f64012;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
}

/* Estilo de los enlaces del Navbar */
.nav-link {
    color: #cfcfcf;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: color 0.3s ease, background-color 0.3s ease, transform 0.2s ease;
}

/* Efecto hover y activo en enlaces */
.nav-link:hover {
    color: #f64012; /* Naranja en hover */
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.nav-link:focus,
.nav-link:active {
    background-color: transparent !important;
    color: #cfcfcf !important;
    box-shadow: none !important;
}

/* Dropdown personalizado */
#basic-nav-dropdown .dropdown-menu {
    background-color: #3a4149;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Elementos del dropdown */
#basic-nav-dropdown .dropdown-item {
    color: #cfcfcf;
    padding: 10px 20px;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
    border-radius: 4px;
}

/* Hover y estado activo en elementos del dropdown */
#basic-nav-dropdown .dropdown-item:hover {
    background-color: #f64012;
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(246, 64, 18, 0.3);
}

#basic-nav-dropdown .dropdown-item:focus,
#basic-nav-dropdown .dropdown-item:active {
    background-color: transparent !important;
    color: #cfcfcf !important;
    box-shadow: none !important;
}

/* Título del menú desplegable */
#basic-nav-dropdown .dropdown-toggle {
    color: #cfcfcf;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover en el título del menú desplegable */
#basic-nav-dropdown .dropdown-toggle:hover {
    color: #f64012;
    background-color: rgba(255, 255, 255, 0.1);
}

#basic-nav-dropdown.show .dropdown-toggle {
    background-color: rgba(246, 64, 18, 0.1);
    color: #ffffff;
}

/* Toggler para dispositivos móviles */
.navbar-toggler {
    border-color: #f64012;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(246, 64, 18, 0.8)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
    .navbar {
        padding: 10px 15px;
    }

    .navbar-brand {
        font-size: 1.3rem;
    }

    .logo {
        width: 100px;
    }
}
