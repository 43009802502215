


/* === Estilos de Accordion === */

/* Contenedor principal de acordeón */
.accordion {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-top: 15px;
}

/* Estilo de cada elemento del acordeón */
.accordion-item {
    border: none;
    margin-bottom: 8px;
}

/* Cabecera del acordeón */
.accordion-button {
    background-color: #3a4149;
    color: #f7f7f7;
    font-weight: bold;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover y foco en el botón de la cabecera */
.accordion-button:hover,
.accordion-button:focus {
    background-color: #ff5733;
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Cabecera del acordeón cuando está activa */
.accordion-button:not(.collapsed) {
    background-color: #ff5733;
    color: #ffffff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
}

/* Cuerpo del acordeón */
.accordion-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    color: #333333; /* Color de texto */
}

/* Separador superior en el cuerpo */
.accordion-body::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-bottom: 10px;
}

/* Ajustes para el botón de cabecera en dispositivos móviles */
@media (max-width: 768px) {
    .accordion-button {
        font-size: 1rem;
        padding: 12px 15px;
    }
    
    .tab-button .nav-link {
        font-size: 1rem;
        padding: 8px 15px;
    }
}
