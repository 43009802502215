.nutrition-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 10px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.card-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.food-icon {
  color: #f64012;
}

.card-title {
  color: #483333;
  font-weight: bold;
}

.nutrition-details p {
  font-size: 0.9rem;
  color: #555;
}

.observations {
  font-weight: bold; /* Negrita */

}

.nutrition-detail-button {
  margin-top: 10px;
  background-color: #f64012;
  border: none;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nutrition-detail-button:hover {
  background-color: #c23010;
}
